.sparkle-wrapper:hover > .sparkle {
  --animation-timer: 0.5s;
  --initial-scale: 0.3;
  --final-scale: 1.2;

  display: block;
  animation: smootheScaling var(--animation-timer) ease-in-out alternate
    infinite;
}

.sparkle {
  display: none;
  animation: fadeToHide 0.3s ease-out forwards;
}

@keyframes fadeToHide {
  0% {
    display: flex;
  }

  100% {
    opacity: 0;
  }
}

@keyframes smootheScaling {
  0% {
    opacity: 0;
    transform: scale(var(--initial scale));
  }

  100% {
    transform: scale(var(--final-scale));
  }
}
